import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce, snakeCase } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import validation from '@/mixins/validation'
import course from '@/mixins/course'

import MainEditor from '@/components/editor/index.vue'
import ImageFile from '@/components/image-file/index.vue'
import SlugNameInput from '@/components/slug-name-input/index.vue'

import SelectPackages from '@/components/forms/SelectPackages';
import { courseContentEventTypes } from '@/modules/courses/helpers/content-events'

import SelectUserPosition from '@/components/select-user-position';

export default {
  name: 'courses-contents-events-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        slug: '',
        link: '',
        typeId: '',
        startedAt: '',
        endedAt: '',
        displayTo: '',
        statusId: '',
        timezone: '',
        sort: 0,
        description: '',
        packages: [],
        speakers: []
      },
      menu1: false
    }
  },
  components: {
    MainEditor,
    SlugNameInput,
    ImageFile,
	SelectPackages,
	  SelectUserPosition
  },
  computed: {
    ...mapGetters({
      courseInfo: 'coursesInfo/info',
      setting: 'courses/setting',
      isLoading: 'contentTimetable/isLoading',
      packagesList: 'coursesPackages/list',
      profile: 'profile/profile',
      speakers: 'user/speakers',
      speakersLoading: 'user/speakersLoading',
      info: 'coursesInfo/info',
      currentItem: 'contentTimetable/currentItem',
      updateImageLoading: 'contentTimetable/updateImageLoading'
    }),
    timezones () {
      if (this.info) {
        const item = this.profile.countries.data.find(e => {
          if (e.type === 'access' && this.info.country?.data?.country_id === e.country_id) {
            return e
          }
        })
        return item?.country?.data?.timezones.data || []
      }
      return []
    },
    courseContentEventTypes
  },
  created () {
    if (this.currentItem) {
      this.setData()
      this.fetchSpeakers({
		  isAuthors: false
      }).then(() => {
        this.setData()
      })
    } else {
      this.setDefaultDateForm()
      this.fetchSpeakers({
		  isAuthors: false
      })
    }
    if (!this.info.hasOwnProperty('id')) {
      this.fetchCourse({ id: this.$route.params.course_id })
    }

    this.$on('event-save', () => {
      this.submit()
    })
  },
  methods: {
    ...mapActions({
      fetchCourse: 'coursesInfo/INFO_GET_COURSE',
      update: 'contentTimetable/UPDATE',
      create: 'contentTimetable/CREATE',
      fetchSpeakers: 'user/GET_SPEAKERS',
      updateImage: 'contentTimetable/UPDATE_IMAGE'
    }),
    ...mapMutations({
      setItem: 'contentTimetable/SET_ITEM'
    }),

    onReloadUserPosition(){
        this.fetchSpeakers({
            isAuthors: false,
        })
    },

    updateImageLocal (params) {
      params.append('course_id', this.$route.params.course_id)
      params.append('event_id', this.currentItem.id)
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t('success_updated'))
      })
    },
    resetValidation () {
      this.showActionsDialog = false
      this.setItem(null)
    },
    setDefaultDateForm () {
      // set default data in fields when created item
      const startedAt = moment().utcOffset(0).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      this.payload.startedAt = moment(startedAt).add(2, 'days').format('YYYY-MM-DD HH:mm:ss')

      const endDate = moment().utcOffset(0).set({
        hour: 2,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      const finishDate = moment(endDate).add(2, 'days').format('YYYY-MM-DD HH:mm:ss')
      this.payload.endedAt = finishDate
      this.payload.displayTo = finishDate

      this.payload.timezone = this.timezones.length ? this.timezones[0].name : ''
    },
    setData () {
      Object.assign(this.payload, this.currentItem)
      this.payload.packages = this.currentItem.packages.data.map(e => e.id)
      this.payload.speakers = this.currentItem?.speakers?.data.map(e => e.user_position_id)
      this.search = this.payload.speakers
    },
    createRequestPayload () {
      const data = Object.assign(this.payload)
      const formData = {}
      Object.entries(data).forEach(e => {
        formData[snakeCase(e[0])] = e[1]
      })
      if (this.currentItem) {
        formData._method = 'patch'
        formData.id = this.currentItem.id
      }
      formData.course_id = this.$route.params.course_id
      formData.started_at = this.formattedDate(this.payload.startedAt)
      formData.ended_at = this.formattedDate(this.payload.endedAt)
      formData.display_to = this.formattedDate(this.payload.displayTo)
      return formData
    },
    // speakers
    changeInputSpeakers: debounce(function (val) {
      if (!val) return
      if (this.search?.full_name !== val) {
        this.fetchSpeakers({
          keywords: val,
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.payload.speakers = e.map(e => e.id)
      this.search = e
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    closeDialog () {
      this.$emit('close-dialog')
    },

    // submit
    submit () {
      this.$refs.form.validate()
      this.$refs.observer.validate()
      if (this.valid) {
        if (!this.currentItem && !this.payload.hasOwnProperty('id')) {
          this.create({
            ...this.createRequestPayload(),
            sort: 0
          }).then(response => {
            this.$toasted.success(this.$t('success_created'))
          })
        } else {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
          })
        }
      }
    }
  }
}
