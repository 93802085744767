import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'

import mixins from '@/mixins'
import course from '@/mixins/course'
import validation from '@/mixins/validation'

import Draggable from 'vuedraggable'
import ViewDialog from '../dialog-view/index.vue'

import { courseContentEventTableHead } from '@/modules/courses/helpers/content-events'

export default {
  name: 'courses-contents-events-main',
  mixin: [course, mixins, validation],
  data () {
    return {
      showRemoveDialog: false,
      showActionsDialog: false,
      valid: false,
      removingItem: null,
      events: []
    }
  },
  components: {
    Draggable,
    ViewDialog
  },
  watch: {
    'list' () {
      this.events = this.list
    }
  },
  computed: {
    ...mapGetters({
      filterKeywords: 'contentTimetable/filterKeywords',
      list: 'contentTimetable/list',
      isListLoading: 'contentTimetable/isListLoading',
      isLoading: 'contentTimetable/isLoading',
      listLength: 'contentTimetable/listLength',
      limit: 'contentTimetable/limit',
      skip: 'contentTimetable/skip',
      currentItem: 'contentTimetable/currentItem'
    }),
    courseContentEventTableHead
  },
  created () {
    if (!this.list.length) {
      this.fetchList({ course_id: this.$route.params.course_id }).then(() => {
        this.events = this.list
      })
    } else {
      this.events = this.list
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'contentTimetable/GET_LIST',
      remove: 'contentTimetable/DELETE',
      updateSort: 'contentTimetable/UPDATE_SORT'
    }),
    ...mapMutations({
      setItem: 'contentTimetable/SET_ITEM'
    }),
    resetValidation () {
      Object.keys(this.payload).forEach(e => {
        this.payload[e] = ''
      })
      this.showActionsDialog = false
      this.setItem(null)
    },
    changeDialogActions (item = null, status) {
      this.setItem(item)
      this.showActionsDialog = status
    },
    openDialogDelete (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    removeListItem () {
      this.remove({
        course_id: this.$route.params.course_id,
        id: this.removingItem.id
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    formattedDate (date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    sendRequest: debounce(function () {
      this.fetchList({ course_id: this.$route.params.course_id })
    }, 1000),
    changeSort: debounce(function (e) {
      if (e.hasOwnProperty('moved')) {
        const formData = {
          course_id: this.$route.params.course_id,
          events: this.events.map(e => e.id)
        }
        this.updateSort(formData).then(() => {
          this.$toasted.success(this.$t('success_change_position'))
        })
      }
    }, 2000)
  }
}
