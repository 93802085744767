export function courseContentEventTableHead () {
  return [
    {
      text: 'Id',
      align: 'start',
      value: 'id',
      sortable: false
    },
    {
      text: 'Name',
      value: 'name',
      sortable: false
    },
    {
      text: 'Start date',
      value: 'startedAt',
      sortable: false
    },
    {
      text: 'End date',
      value: 'endedAt',
      sortable: false
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]
}

export function courseContentEventTypes () {
  return [
    {
      name: 'Live',
      id: 1
    },
    {
      name: 'Online',
      id: 2
    }
  ]
}
