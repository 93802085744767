import { mapActions, mapGetters, mapMutations } from 'vuex'
import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'course-contents-events-seo',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      seo: 'contentTimetable/seo',
      seoLoading: 'contentTimetable/seoLoading',
      updateSeoLoading: 'contentTimetable/updateSeoLoading',
      currentItem: 'contentTimetable/currentItem'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      const { course_id } = this.$route.params
      this.fetchData({
        course_id: course_id,
        id: this.currentItem.id
      })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
    
  },
  methods: {
    ...mapActions({
      fetchData: 'contentTimetable/GET_SEO',
      update: 'contentTimetable/UPDATE_SEO'
    }),
    ...mapMutations({
      setSeo: 'contentTimetable/SET_SEO'
    }),
    submit (formData) {
      const { course_id } = this.$route.params
      formData.course_id = course_id
      formData.event_id = this.currentItem.id

      this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
      })
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
